import actions from './actions'

const initialState = {
  loading: false,
  updating: false,
  removing: false,
  uploading: false,
  businessDetails: null,
  businessName: null,
  themeColor: '#f86d64',
  backgroundImage: null,
  data: null,
  data1: null,
  data2: null,
  actiontype: null,
  form_name: null,
  selectedData: null,
  errors: {
    create: null,
    update: null,
  },
}

export default function reservationSetupReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.SET_ERROR_STATE:
      return { ...state, errors: { ...state.errors, ...action.payload } }
    default:
      return state
  }
}
