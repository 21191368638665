import React, { useEffect, Component } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Helmet } from "react-helmet";
import { selectLocations } from '../redux/locations/selectors'
import LocationAction from '../redux/locations/actions'
import './Location.css';
import search from './search.svg';
import clear from './clear.svg';
import location_icon from './location.svg';
import food from './food.png';
import delivery from './delivery.svg';
import pickup from './pickup.svg';
import LocationSearchInput from './LocationSearchInput';
import { geolocated } from "react-geolocated";
import renderer from "react-test-renderer";
// import GeoLocation from './GeoLocation';

function Location() {

    const host = process.env.REACT_APP_SITE_URL || window.location.hostname
    // const path = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1)
    const locations = useSelector(selectLocations)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch({
            type: LocationAction.GET_LOCATIONS,
            payload: {
                host,
            },
        })
    }, [dispatch, host])

    const [message, setMessage] = React.useState([])

    useEffect(() => {
        class GeoLocation1 extends Component {
            render() {
                if (!this.props.isGeolocationAvailable) {
                    setMessage('Please, update your or change the browser')
                }
                if (!this.props.isGeolocationEnabled) {
                    setMessage('Please enable location on your browser')
                } else {
                    if (!this.props.coords) {
                        // setMessage('Getting the location data')
                    }
                }
                if (this.props.coords) {
                    const lat = this.props.coords.latitude
                    const lng = this.props.coords.longitude
                    setMessage(null)
                    dispatch({
                        type: LocationAction.GET_LOCATIONS,
                        payload: {
                            host,
                            lat,
                            lng,
                        },
                    })
                }
                return (
                    'success'
                )
            }
        }
        const Wrapped1 = geolocated()(GeoLocation1)
        renderer.create(<Wrapped1 />)
    }, [dispatch, host]);

    const onSelectedAddress = (lat, lng) => {
        setMessage(null)
        document.getElementById("clear_icon_div").classList.remove('hide_icon')
        dispatch({
            type: LocationAction.GET_LOCATIONS,
            payload: {
                host,
                lat,
                lng,
            },
        })
    }

    class GeoLocation extends Component {
        render() {
            if (!this.props.isGeolocationAvailable) {
                setMessage('Please, update your or change the browser')
            }
            if (!this.props.isGeolocationEnabled) {
                setMessage('Please enable location on your browser')
            } else {
                if (!this.props.coords) {
                    // setMessage('Getting the location data')
                }
            }
            if (this.props.coords) {
                onSelectedAddress(this.props.coords.latitude, this.props.coords.longitude)
            }
            return (
                'success'
            )
        }
    }

    const onCurrentAddress = () => {
        setMessage(null)
        const Wrapped = geolocated()(GeoLocation)
        renderer.create(<Wrapped />)
    }

    const clearInput = () => {
        document.getElementById('search-input').value = '';
        document.getElementById("clear_icon_div").classList.add('hide_icon')
    }

    let page_title = `Online Ordering | Plento`

    if (locations.businessName) {
        page_title = `${locations.businessName} | Online Ordering | Plento`
    }

    return (
        <div className='location'>
            <Helmet>
                <title>{page_title}</title>
            </Helmet>
            <div hidden={locations.loading && !locations.businessName} className='location_title'>
                <h1 className='location_title_name'>{locations.businessName}</h1>
            </div>
            <div className='location_container'>
                <h4 className='location_title_name location_fontsize'>Enter your location to find stores near you</h4><br />
                <div className='location_container_div'>
                    <div className='location_input_search'>
                        <div className="search_icon">
                            <source media="(min-width: 1024px)" />
                            <source media="(min-width: 767px)" />
                            <img className='image_icon' src={search} alt="Search" title="Search" />
                        </div>
                        <LocationSearchInput onSelectedAddress={onSelectedAddress}></LocationSearchInput>
                        <div id="clear_icon_div" className="clear_icon hide_icon" onClick={clearInput}>
                            <source media="(min-width: 1024px)" />
                            <source media="(min-width: 767px)" />
                            <img className='image_icon' src={clear} alt="Search" title="Clear" />
                        </div>
                    </div>
                    <button type="button" data-testid="searchbutton" className="location_button" style={{ background: locations.themeColor }} onClick={onCurrentAddress}>
                        <div className="location_button_icon">
                            <source media="(min-width: 1024px)" />
                            <source media="(min-width: 767px)" />
                            <img className='image_icon' src={location_icon} alt="Near me" title="Near me" />
                        </div>
                        Use my location
                    </button>
                </div>
                <p className="error_message">
                    {message}
                </p>
            </div>
            <div hidden={locations.loading && !locations.businessDetails} className='location_list'>
                <h4 className='location_title_name location_fontsize'>Choose your location to place order online</h4><br />
                <div>
                    {locations?.businessDetails?.map((storeInfo) => {
                        const { business, opening_status, kilometer, banner_image_url, order_url, food_delivery_platform } = storeInfo
                        let food_image = food
                        if (banner_image_url) {
                            food_image = banner_image_url
                        }
                        let location_name = business.location.name
                        if (business?.location_name) {
                            location_name = business.location_name
                        }
                        let default_order_url = order_url
                        if (food_delivery_platform?.length > 0) {
                            food_delivery_platform.forEach(({ is_default_wesite_url, ordering_url }) => {
                                if (is_default_wesite_url) {
                                    default_order_url = ordering_url
                                }
                            })
                        }
                        return (
                            <div data-testid="merchant-location-list-card" className='location_view'>
                                <a className='location_view_link' href={default_order_url}>
                                    <div className='location_image'>
                                        <img src={food_image} width="90" height="90" alt={business.name} className='location_image_style' />
                                        {opening_status.status !== 'Open' && (
                                            <div data-testid="merchant-state-overlay" className='location_image_status'>
                                                <p className='location_image_status_text'>Closed</p>
                                            </div>
                                        )}
                                    </div>
                                    <div className='location_info'>
                                        <div className='location_info_header'>
                                            <h6 className='location_info_header_text'>{business.name} <br className='show_only_mobile' />({location_name})</h6>
                                            {kilometer && (
                                                <p data-testid="merchant-location-card-distance" className='location_info_header_dollar'>{kilometer}km</p>
                                            )}
                                        </div>
                                        <div className='location_info_container'>
                                            <div className='store_info'>
                                                {opening_status.status === 'Open' && (
                                                    <p className='store_info_opened'>Open</p>
                                                )}
                                                {opening_status.status !== 'Open' && (
                                                    <p className='store_info_closed'>Closed - {opening_status.message}</p>
                                                )}
                                                <p className='store_info_address'>{business.location.address.street}</p>
                                                <div className='order_type'>
                                                    {business?.setting?.delivery?.is_delivery_available && (
                                                        <div className='order_type_info'>
                                                            <div className="order_type_image">
                                                                <source media="(min-width: 1024px)" />
                                                                <source media="(min-width: 767px)" />
                                                                <img className='image_icon' src={delivery} alt="Delivery" title="Delivery" />
                                                            </div>
                                                            Delivery
                                                        </div>
                                                    )}
                                                    {business?.setting?.pick_up?.is_pick_up_available && (
                                                        <div className='order_type_info'>
                                                            <div className="order_type_image">
                                                                <source media="(min-width: 1024px)" />
                                                                <source media="(min-width: 767px)" />
                                                                <img className='image_icon' src={pickup} alt="Pick Up" title="Pick Up" />
                                                            </div>
                                                            Pick Up
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <br className='show_only_mobile' />
                                            {opening_status.status === 'Open' && (
                                                <a className='store_info_button' href={default_order_url} style={{ background: locations.themeColor }}>
                                                    Order Now
                                                </a>
                                            )}
                                            {opening_status.status !== 'Open' && (
                                                <a className='store_info_button' href={default_order_url} style={{ background: locations.themeColor }}>
                                                    Pre Order
                                                </a>
                                            )}
                                        </div>
                                    </div>
                                </a>
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className='location_footer'>
                <div className='location_footer_div'>
                    <span className='location_footer_text'>Powered by Plento</span>
                </div>
                <div>
                    <span><a href='https://www.plento.io/terms' target='_blank' rel='noopener noreferrer' className='location_footer_link'>Terms</a></span>
                    <span className='location_footer_line'>|</span>
                    <span><a href='https://www.plento.io/privacy' target='_blank' rel='noopener noreferrer' className='location_footer_link'>Privacy</a></span>
                </div>
            </div>
        </div>
    )
}

export default Location